import { ref } from '@vue/composition-api'
import { axios } from '@/lib/axios'
import { handleError } from '@/lib/error-handler'
import html2canvas from 'html2canvas'
import { UserModule } from '@/store/modules/user'
import { getBrowserType } from '@/lib/filters'
import { Message } from 'element-ui'
// tslint:disable-next-line
const config = require('../../../package.json')

// html2canvas 获取截图
export const useGetScreenshot = () => {
  const screenshotLoading = ref(false)
  const formData = ref(new FormData())
  const canvasCopy: any = ref({})

  const screenshotImg = async () => {
    try {
      screenshotLoading.value = true
      const appId = document.querySelector('.app-wrapper') as any
      if (!appId) {
        return
      }
      window.scrollTo(0, 0)
      await html2canvas(document.body).then(async (canvas) => {
        canvasCopy.value = canvas
        const blob = (await new Promise((resolve) => canvas.toBlob(resolve))) as any
        formData.value.delete('image')
        formData.value.append('image', blob, 'image.png')
      })
    } catch (e) {
      handleError(e)
    } finally {
      screenshotLoading.value = false
    }
  }
  return { formData, screenshotLoading, canvasCopy, screenshotImg }
}

// 上传图片并获取 image_key
export const useUploadImage = () => {
  const imgRes: any = ref({})
  const uploadLoading = ref(false)
  const uploadHandler = async (formData: any) => {
    try {
      uploadLoading.value = true
      const {
        data: { data },
      } = await axios.post('/utils/lark-image/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      imgRes.value = data
    } catch (e) {
      handleError(e)
    } finally {
      uploadLoading.value = false
    }
  }
  return { imgRes, uploadLoading, uploadHandler }
}

/**
 * 功能: 发送图片信息到 lark 机器人
 * webhook: VUE_APP_SCREENSHOT_WEBHOOK
 * 关键字: 应用报警
 */
export const sendNotifyToWebhook = () => {
  const sendLoading = ref(false)
  const sendResult: any = ref({})
  const sendHandler = async (type: string, imgKey: string, errorInfos: any[], remark: string, showSuccess: boolean = true) => {
    const message = {
      msg_type: 'post',
      content: {
        post: {
          zh_cn: {
            title: 'SSO 应用报警',
            content: [
              [
                {
                  tag: 'text',
                  text: '访问域名: ',
                },
                {
                  tag: 'text',
                  text: `${window.location.protocol}//${window.location.host}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: '触发类型: ',
                },
                {
                  tag: 'text',
                  text: `${type}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: '用户: ',
                },
                {
                  tag: 'text',
                  text: `${UserModule.username}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: '用户本地版本号: ',
                },
                {
                  tag: 'text',
                  text: `${config.version}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: `浏览器类型: `,
                },
                {
                  tag: 'text',
                  text: `${getBrowserType(navigator.userAgent)}`,
                },
              ],
              [
                {
                  tag: 'text',
                  un_escape: true,
                  text: '当前页面URL:',
                },
                {
                  tag: 'a',
                  text: '超链接',
                  href: `${window.location.href}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: '备注: ',
                },
                {
                  tag: 'text',
                  text: `${remark ? remark : '主动捕获'}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: '报错接口数: ',
                },
                {
                  tag: 'text',
                  text: `${errorInfos.length}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: '报错日志: ',
                },
                {
                  tag: 'text',
                  text: `${errorInfos.length ? JSON.stringify(errorInfos) : '未报错'}`,
                },
              ],
              [
                {
                  tag: 'text',
                  text: 'image_key: ',
                },
                {
                  tag: 'text',
                  text: `${imgKey}`,
                },
              ],
              [
                {
                  tag: 'img',
                  image_key: `${imgKey}`,
                  width: 300,
                },
              ],
            ],
          },
        },
      },
    }

    try {
      sendLoading.value = true
      const url = '/hook/screenshot'
      if (!url) return
      const { data } = await axios({
        method: 'POST',
        baseURL: '/open-apis/bot/v2',
        url,
        data: Object.assign({}, message),
      })
      sendResult.value = data
      if (sendResult.value.StatusCode !== 0) {
        Message({ message: `${sendResult.value.StatusMessage || 'System is busy'}, please try again later`, type: 'error' })
        return
      }
      if (showSuccess) {
        Message({
          showClose: true,
          message: `Send successfully, thank you!`,
          type: 'success',
          duration: 6000,
        })
      }
    } catch (e) {
      handleError(e)
    } finally {
      sendLoading.value = false
    }
  }
  return { sendResult, sendLoading, sendHandler }
}
