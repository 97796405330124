import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Layout from '@/views/layout/Layout.vue'

Vue.use(Router)

/**
 * redirect:              如果 redirect: noredirect 则在单击面包屑时不会重定向
 * meta: {
 *   title: 'title'       子菜单和面包屑中显示的名称 (推荐设置)
 *   icon: 'svg-name'     侧边栏中显示的图标
 *   hidden: true         如果 hidden: true 则此路由不会显示在边栏中, 默认值为 false
 *   alwaysShow: true     如果设置为 true, 它将始终显示根菜单;
 *                        如果未设置, 则仅在其子项下有一条以上线路时以嵌套模式显示
 * }
 */

export let lastPath: string = ''

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/white-list/Login.vue'),
  },
  {
    path: '/redirect-authentication',
    name: 'RedirectAuthentication',
    component: () => import(/* webpackChunkName: "redirectAuthentication" */ '@/views/white-list/RedirectAuthentication.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/white-list/404.vue'),
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: () => import(/* webpackChunkName:"settings" */ '@/views/settings/set-password/Index.vue'),
  },
  {
    path: '/',
    component: Layout,
    redirect: '/redirect-authentication',
    meta: { hidden: true },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: { moduleName: 'My Resources', hidden: true },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/white-list/Dashboard.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: { title: 'Profile', alwaysShow: false, hidden: true },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        meta: { moduleName: 'Profile', title: 'Profile' },
        component: () => import(/* webpackChunkName: "profile" */ '@/views/white-list/Profile.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: { title: 'Settings', alwaysShow: false, hidden: true },
    children: [
      {
        path: 'settings',
        name: 'Settings',
        meta: { moduleName: 'Settings', title: 'Settings' },
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Index.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: { title: 'My Resources', icon: 'resources', alwaysShow: false, hidden: false },
    children: [
      {
        path: 'resources-examination',
        name: 'MyResources',
        meta: { moduleName: 'My Resources', title: 'My Resources', icon: 'resources' },
        component: () => import(/* webpackChunkName: "myResources" */ '@/views/my-resources/Index.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: { title: 'Application', icon: 'approve', alwaysShow: false, hidden: false },
    children: [
      {
        path: 'approve-start',
        name: 'ApproveStart',
        meta: { moduleName: 'Approve', icon: 'approvals', title: 'My Applications' },
        component: () => import(/* webpackChunkName: "approve" */ '@/views/approve/ApproveStart.vue'),
      },
      {
        path: 'approve-my-guide',
        name: 'ApproveMyGuide',
        meta: { moduleName: 'Approve', icon: 'my-approval', title: 'Applications' },
        component: () => import(/* webpackChunkName: "approve" */ '@/views/approve/ApplicationsGuide.vue'),
      },
      {
        path: 'approve-my',
        name: 'ApproveMy',
        meta: { moduleName: 'Approve', icon: 'my-approval', title: 'Applications', hidden: true },
        component: () => import(/* webpackChunkName: "approve" */ '@/views/approve/ApproveMy.vue'),
      },
      {
        path: 'approve-my-detail/:task_number',
        name: 'ApproveMyDetail',
        meta: { moduleName: 'Approve', title: 'My Applications Detail', hidden: true },
        component: () => import(/* webpackChunkName: "approve" */ '@/views/approve/ApproveMyDetail.vue'),
      },
      {
        path: 'authorization',
        name: 'ApproveAuthorization',
        meta: { moduleName: 'Approve', title: 'Authorization', hidden: true },
        component: () => import(/* webpackChunkName: "approve" */ '@/views/approve/Authorization.vue'),
      },
      // {
      //   path: 'offboarding-workflow-operator',
      //   name: 'OffboardingWorkflowOperator',
      //   meta: { moduleName: 'Workflow_Employee', icon: 'workflow', title: 'Workflow' },
      //   component: () =>
      //     import(/* webpackChunkName: "offboarding" */ '@/views/offboarding/offboarding-operator-process/Index.vue'),
      // },
    ],
  },
  {
    path: '/demission',
    component: Layout,
    meta: { title: 'Offboarding', icon: 'delete-user', alwaysShow: false, hidden: true },
    children: [
      {
        path: 'apply-demission',
        name: 'ApplyDemission',
        meta: { moduleName: 'Offboarding', title: 'Offboarding', hidden: true },
        component: () => import(/* webpackChunkName: "approve" */ '@/views/demission/ApplyDemission.vue'),
      },
    ],
  },
  {
    path: '/report',
    component: Layout,
    meta: {
      title: 'Organization Chart',
      icon: 'organization-chart',
      alwaysShow: false,
      hidden: false,
    },
    children: [
      {
        path: 'employment-organization-chart',
        name: 'EmploymentOrganizationChart',
        meta: { moduleName: 'Organization Chart', title: 'Organization Chart', icon: 'organization-chart' },
        component: () => import(/* webpackChunkName: "organizationChart" */ '@/views/report/EmploymentOrganizationChart.vue'),
      },
    ],
  },
  {
    path: '/work-wechat-organization-chart',
    name: 'WorkWeChatEmploymentOrganizationChart',
    meta: { moduleName: 'Organization Chart', hidden: true },
    component: () => import(/* webpackChunkName: "organizationChart" */ '@/views/report/EmploymentOrganizationChart.vue'),
  },
  {
    path: '/',
    component: Layout,
    meta: {
      title: 'Competency Framework',
      icon: 'skills',
      alwaysShow: false,
      hidden: false,
    },
    children: [
      {
        path: 'competency-framework',
        name: 'CompetencyFramework',
        meta: { moduleName: 'Competency Framework', title: 'Competency Framework', icon: 'skills' },
        component: () => import(/* webpackChunkName: "jobRank" */ '@/views/competency-framework/Index.vue'),
      },
    ],
  },
  {
    path: '/team',
    component: Layout,
    meta: {
      title: 'Team',
      icon: 'team',
      alwaysShow: true,
    },
    children: [
      {
        path: 'team-dashboard',
        name: 'TeamDashboard ',
        meta: { moduleName: 'Team Leave', title: 'Dashboard', icon: 'dashboard' },
        component: () => import(/* webpackChunkName: "departmentManagement" */ '@/views/team/dashboard/Index.vue'),
      },
      {
        path: 'team-resource',
        name: 'TeamResource',
        meta: { moduleName: 'Team Resource', title: 'Resources', icon: 'resourcesManagement' },
        component: () => import(/* webpackChunkName: "team" */ '@/views/team/Index.vue'),
      },
    ],
  },
  {
    path: '/administrator',
    component: Layout,
    meta: {
      title: 'Admin',
      icon: 'admin',
      alwaysShow: true,
    },
    children: [
      {
        path: 'role-management',
        name: 'RoleManagement',
        meta: { moduleName: 'Role', title: 'Role', icon: 'role' },
        component: () => import(/* webpackChunkName: "roleManagement" */ '@/views/roles/Index.vue'),
      },
      {
        path: 'user-management',
        name: 'UserManagement',
        meta: { moduleName: 'User', title: 'User', icon: 'users' },
        component: () => import(/* webpackChunkName: "userManagement" */ '@/views/user/Index.vue'),
      },
      {
        path: 'resources-management',
        name: 'ResourcesManagement',
        meta: { moduleName: 'Resources', title: 'Resources', icon: 'resourcesManagement' },
        component: () =>
          import(
            /* webpackChunkName: "resourcesManagement" */ '@/views/administrator/resources-management/ResourcesManagement.vue'
          ),
      },
      {
        path: 'template-management',
        name: 'TemplateManagement',
        meta: { moduleName: 'Template', title: 'Template', icon: 'template' },
        component: () => import(/* webpackChunkName: "templateManagement" */ '@/views/administrator/TemplateManagement.vue'),
      },
      {
        path: 'meeting-summary',
        name: 'MeetingSummary',
        meta: { moduleName: 'Meeting Summary', title: 'Meeting Summary', icon: 'meeting-summary' },
        component: () => import(/* webpackChunkName: "meetingSummary" */ '@/views/meeting-summary/Index.vue'),
      },
      {
        path: 'work-wechat-usage',
        name: 'WorkWeChatUsage',
        meta: { moduleName: 'WeCom Usage', title: 'WeCom Usage', icon: 'wechat' },
        component: () => import(/* webpackChunkName: "weChatWorkUsage" */ '@/views/administrator/WorkWeChatUsage.vue'),
      },
      {
        path: 'offboarding',
        name: 'TPSOffboarding',
        meta: { moduleName: 'TPS Offboarding', title: 'TPS Offboarding', icon: 'offboarding' },
        component: () => import(/* webpackChunkName: "offboarding" */ '@/views/offboarding-admin/Index.vue'),
      },
    ],
  },
  {
    path: '/relationship',
    component: Layout,
    meta: {
      title: 'HR',
      icon: 'relationship',
      alwaysShow: true,
      hidden: false,
    },
    children: [
      // {
      //   path: 'hr-workspace',
      //   name: 'HrWorkspace',
      //   meta: { moduleName: 'HR Workspace', title: 'HR Workspace', icon: 'relationship' },
      //   component: () => import(/* webpackChunkName: "Hr-Workspace" */ '@/views/relationship/Index.vue'),
      // },
      {
        path: 'pre-onboarding',
        name: 'Pre-onboarding',
        meta: { moduleName: 'Pre-onboarding', title: 'Pre-onboarding', icon: 'pre-add-user' },
        component: () => import(/* webpackChunkName: "pre-onboarding" */ '@/views/relationship/pre-onboarding/Index.vue'),
      },
      {
        path: 'pre-onboarding-detail',
        name: 'CreatePreOnboarding',
        meta: { moduleName: 'Pre-onboarding', title: 'Pre-onboarding', hidden: true },
        component: () =>
          import(/* webpackChunkName: "pre-onboarding" */ '@/views/relationship/pre-onboarding/PreOnboardingForm.vue'),
      },
      {
        path: 'onboarding',
        name: 'Onboarding',
        meta: { moduleName: 'Onboarding', title: 'Onboarding', icon: 'add-user' },
        component: () => import(/* webpackChunkName: "onboarding" */ '@/views/relationship/onboarding/Index.vue'),
      },
      {
        path: 'department-management',
        name: 'DepartmentManagement',
        meta: { moduleName: 'Contacts', title: 'Contacts', icon: 'contacts' },
        component: () => import(/* webpackChunkName: "departmentManagement" */ '@/views/relationship/DepartmentManagement.vue'),
      },
      {
        path: 'employee-audit',
        name: 'EmployeeAudit',
        meta: { moduleName: 'Employee Audit', title: 'Employee Audit', icon: 'user-audit' },
        component: () => import(/* webpackChunkName: "employeeAudit" */ '@/views/employee-audit/Index.vue'),
      },
      {
        path: 'employment-organization-char-editor',
        name: 'OrganizationChartEditor',
        meta: { moduleName: 'Organization Structure', title: 'Organization Structure', icon: 'department' },
        component: () => import(/* webpackChunkName: "organizationChart" */ '@/views/relationship/OrganizationChartEditor.vue'),
      },
      {
        path: 'workflow-pic',
        name: 'WorkflowPICManager',
        meta: { moduleName: 'Workflow PIC', title: 'Workflow PIC', icon: 'workflow', hidden: false },
        component: () => import(/* webpackChunkName: "organizationChart" */ '@/views/relationship/workflow-pic/Index.vue'),
      },
      {
        path: 'offboarding-start',
        name: 'OffboardingStart',
        meta: { moduleName: 'Offboarding_HRBP', title: 'Offboarding (HRBP)', icon: 'delete-user' },
        component: () => import(/* webpackChunkName: "offboarding" */ '@/views/offboarding/offboarding-hrbp/Entry.vue'),
      },
      {
        path: 'offboarding-create',
        name: 'OffboardingCreate',
        meta: { moduleName: 'Offboarding_HRBP', title: 'Offboarding (HRBP)', hidden: true },
        component: () => import(/* webpackChunkName: "offboarding" */ '@/views/offboarding/offboarding-hrbp/Create.vue'),
      },
      {
        path: 'offboarding-admin',
        name: 'OffboardingAdmin',
        meta: { moduleName: 'Offboarding_Admin', title: 'Offboarding (Admin)', icon: 'delete-user' },
        component: () => import(/* webpackChunkName: "offboarding" */ '@/views/offboarding/offboarding-admin/Entry.vue'),
      },
      {
        path: 'offboarding-process-detail/:roleType',
        name: 'OffboardingProcessDetail',
        meta: { moduleName: 'Offboarding_Common_Detail', title: 'Offboarding Process Detail', hidden: true },
        component: () => import(/* webpackChunkName: "offboarding" */ '@/views/offboarding/common/ProcessDetails.vue'),
      },
      {
        path: 'title',
        name: 'Title',
        meta: { moduleName: 'Title Manage', title: 'Title', icon: 'title' },
        component: () => import(/* webpackChunkName: "title"*/ '@/views/relationship/title/Index.vue'),
      },
      {
        path: 'office',
        name: 'Office',
        meta: { moduleName: 'Office', title: 'Office', icon: 'office' },
        component: () => import(/* webpackChunkName: "jobRank" */ '@/views/office/Index.vue'),
      },
      {
        path: 'company-entity',
        name: 'CompanyEntity',
        meta: { moduleName: 'Company Entity', title: 'Company Entity', icon: 'company-entity' },
        component: () => import(/* webpackChunkName: "jobRank" */ '@/views/company-entity/Index.vue'),
      },
      {
        path: 'outsourced-offboarding',
        name: 'OutsourcedOffboarding',
        meta: { moduleName: 'Outsourced Members', title: 'Outsourced Member', icon: 'outsourced-member' },
        component: () => import(/* webpackChunkName: "outsourcedMember" */ '@/views/outsourced-offboarding/Index.vue'),
      },
    ],
  },
  {
    path: '/email',
    component: Layout,
    meta: {
      title: 'Email',
      icon: 'email',
      alwaysShow: true,
    },
    children: [
      {
        path: 'employee',
        name: 'EmployeeEmail',
        meta: { moduleName: 'Employee Email', title: 'Employee Email', icon: 'employee_email' },
        component: () => import(/* webpackChunkName: "email" */ '@/views/email/employee/Index.vue'),
      },
      {
        path: 'public-introduction',
        name: 'PublicEmailIntroduction',
        meta: { moduleName: 'Shared Mailbox', title: 'Shared Mailbox', icon: 'common_email' },
        component: () => import(/* webpackChunkName: "email" */ '@/views/email/public/IntroductionPage.vue'),
      },
      {
        path: 'public',
        name: 'PublicEmail',
        meta: { moduleName: 'Shared Mailbox', title: 'Shared Mailbox', hidden: true },
        component: () => import(/* webpackChunkName: "email" */ '@/views/email/public/Index.vue'),
      },
      {
        path: 'public-edit',
        name: 'EditPublicEmail',
        meta: { moduleName: 'Shared Mailbox', title: 'Shared Mailbox', hidden: true },
        component: () => import(/* webpackChunkName: "email" */ '@/views/email/public/CreateAndEditEmail.vue'),
      },
      {
        path: 'group-introduction',
        name: 'GroupEmailIntroduction',
        meta: { moduleName: 'Mailing List', title: 'Mailing List', icon: 'group_email' },
        component: () => import(/* webpackChunkName: "email" */ '@/views/email/group/IntroductionPage.vue'),
      },
      {
        path: 'group',
        name: 'GroupEmail',
        meta: { moduleName: 'Mailing List', title: 'Mailing List', hidden: true },
        component: () => import(/* webpackChunkName: "email" */ '@/views/email/group/Index.vue'),
      },
      {
        path: 'group-edit',
        name: 'EditGroupEmail',
        meta: { moduleName: 'Mailing List', title: 'Mailing List', hidden: true },
        component: () => import(/* webpackChunkName: "email" */ '@/views/email/group/CreateAndEditEmail.vue'),
      },
    ],
  },
  {
    path: '/finance',
    component: Layout,
    meta: {
      title: 'Finance',
      icon: 'finance',
      alwaysShow: true,
    },
    children: [
      {
        path: 'mail',
        name: 'FinanceMail',
        meta: { moduleName: 'Finance Mail', title: 'Finance Mail', icon: 'group_email' },
        component: () => import(/* webpackChunkName: "" */ '@/views/finance/Index.vue'),
      },
    ],
  },
  {
    path: '/security',
    component: Layout,
    meta: {
      title: 'Security',
      icon: 'security',
      alwaysShow: true,
    },
    children: [
      {
        path: 'log-review',
        name: 'LogReview',
        meta: { moduleName: 'Log Review', title: 'Log Review', icon: 'logs' },
        component: () => import(/* webpackChunkName: "logReview" */ '@/views/log-review/Index.vue'),
      },
      {
        path: 'user-permission',
        name: 'UserPermission',
        meta: { moduleName: 'Permission(security)', title: 'User Permission', icon: 'permission' },
        component: () => import(/* webpackChunkName: "userPermission" */ '@/views/security-permission/Index.vue'),
      },
    ],
  },
  {
    path: '/kp-team',
    component: Layout,
    meta: {
      title: 'Kredit Pintar Team',
      icon: 'team',
      alwaysShow: true,
    },
    children: [
      {
        path: 'user',
        name: 'KPTeamUser',
        meta: { moduleName: 'KPUser', title: 'Members', icon: 'users' },
        component: () => import(/* webpackChunkName: "kpTeam" */ '@/views/kp-team/user/Index.vue'),
      },
      {
        path: 'resources',
        name: 'KPTeamResources',
        meta: { moduleName: 'KPResource', title: 'Resources', icon: 'resourcesManagement' },
        component: () => import(/* webpackChunkName: "kpTeam" */ '@/views/kp-team/resources/Index.vue'),
      },
      {
        path: 'log-records',
        name: 'EventLogs',
        meta: { moduleName: 'KP Event Logs', title: 'Event Logs', icon: 'logs' },
        component: () => import(/* webpackChunkName: "kpLog" */ '@/views/kp-team/log/Index.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    meta: { title: 'My Training', icon: 'my-training', alwaysShow: false, hidden: false },
    children: [
      {
        path: 'my-employee-training',
        name: 'MyTraining',
        meta: { moduleName: 'My Training', title: 'My Training', icon: 'my-training' },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
      {
        path: '/my-employee-training-certificate/:id',
        name: 'MyEmployeeTrainingCertificate',
        meta: {
          moduleName: 'My Training',
          title: 'My Training',
          icon: 'my-training',
          hiddenLayout: true,
          alwaysShow: false,
          hidden: true,
        },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
      {
        path: '/my-employee-training-exam-do',
        name: 'MyEmployeeTrainingExamDo',
        meta: {
          moduleName: 'My Training',
          title: 'My Training',
          icon: 'my-training',
          hiddenLayout: true,
          alwaysShow: false,
          hidden: true,
        },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
      {
        path: '/my-employee-training-course-do',
        name: 'MyEmployeeTrainingCourseDo',
        meta: {
          moduleName: 'My Training',
          title: 'My Training',
          icon: 'my-training',
          hiddenLayout: true,
          alwaysShow: false,
          hidden: true,
        },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
    ],
  },
  {
    path: '/employee-training',
    component: Layout,
    meta: {
      title: 'Employee Training',
      icon: 'employee-training',
      alwaysShow: true,
    },
    children: [
      {
        path: 'question-bank',
        name: 'QuestionBankManagement',
        meta: { moduleName: 'Training/Question Bank', title: 'Question Bank', icon: 'question-bank' },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
      {
        path: 'examination-paper',
        name: 'TestPaperManagement',
        meta: { moduleName: 'Training/Test Paper', title: 'Examination Paper', icon: 'test-paper' },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
      {
        path: 'course-learning',
        name: 'CourseLearning',
        meta: { moduleName: 'Training/Course Learning', title: 'Course Learning', icon: 'course-learning' },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
      {
        path: '/employee-training/upload-members-history',
        name: 'UploadMembersHistory',
        meta: { moduleName: 'Training/Upload Members', title: 'Upload Members Log', icon: 'test-paper' },
        component: () => import(/* webpackChunkName: "myTraining" */ '@/views/training/MyTraining.vue'),
      },
    ],
  },
  {
    path: '/account-issue',
    name: 'AccountIssue',
    component: () => import(/* webpackChunkName: "accountIssue" */ '@/views/white-list/AccountIssue.vue'),
  },
  { path: '*', redirect: '/404' },
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  base: process.env.BASE_URL,
  routes,
})
