/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'meeting-summary': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M768 779.461V880c0 17.949-14.551 32.5-32.5 32.5h-63c-17.949 0-32.5-14.551-32.5-32.5V714.855a64.008 64.008 0 0118.747-45.26l90.312-90.312A64.667 64.667 0 00768 533.556V368.5c0-35.346 28.654-64 64-64h64c35.346 0 64 28.654 64 64v164.625a64.3 64.3 0 01-18.699 45.333l-154.36 155.277A64.663 64.663 0 00768 779.461zm-.483-569.139c-1.01-54.462 43.343-98.815 97.806-97.806 51.327.952 93.209 42.834 94.161 94.161 1.01 54.462-43.344 98.815-97.806 97.806-51.327-.951-93.21-42.834-94.161-94.161zM670.834 560.5H352.166c-17.436 0-32.175-13.671-32.653-31.101-.496-18.084 14.016-32.899 31.988-32.899h64v-128c0-35.346 28.654-64 64-64h64c35.346 0 64 28.654 64 64v128h64c17.972 0 32.484 14.816 31.988 32.899-.479 17.43-15.218 31.101-32.655 31.101zM415.517 210.322c-1.01-54.462 43.343-98.815 97.806-97.806 51.327.952 93.209 42.834 94.161 94.161 1.01 54.462-43.344 98.815-97.806 97.806-51.327-.951-93.21-42.834-94.161-94.161zM383.5 714.855V880c0 17.949-14.551 32.5-32.5 32.5h-63c-17.949 0-32.5-14.551-32.5-32.5V779.461a64.667 64.667 0 00-18.941-45.727L82.131 578.389A64.067 64.067 0 0163.5 533.221V368.5c0-35.346 28.654-64 64-64h64c35.346 0 64 28.654 64 64v165.056a64.667 64.667 0 0018.941 45.727l90.312 90.312a64.01 64.01 0 0118.747 45.26zM63.517 210.322c-1.01-54.462 43.343-98.815 97.806-97.806 51.327.952 93.209 42.834 94.161 94.161 1.01 54.462-43.343 98.815-97.806 97.806-51.327-.951-93.21-42.834-94.161-94.161zm400.882 414.19c17.43.478 31.101 15.217 31.101 32.653v222.668c0 17.436-13.671 32.175-31.101 32.653-18.084.496-32.899-14.016-32.899-31.988v-224c0-17.97 14.816-32.482 32.899-31.986zm96 0c17.43.478 31.101 15.217 31.101 32.653v222.668c0 17.436-13.671 32.175-31.101 32.653-18.084.496-32.899-14.016-32.899-31.988v-224c0-17.97 14.815-32.482 32.899-31.986z"/>'
  }
})
